import { useIntl } from 'react-intl';

import { Button, ButtonTypes } from '@/components/Button';

export default function Custom404() {
  const { formatMessage } = useIntl();

  return (
    <div className="content">
      <div className="grid-no-result">
        <div>
          <h1 className="title-h2">{formatMessage({ id: '404.title' })}</h1>
          <Button link={'/'} type={ButtonTypes.Button} style="primary">
            {formatMessage({ id: '404.cta' })}
          </Button>
        </div>
      </div>
    </div>
  );
}
